
export const servicesData = [
  {
    id: 'urun-ihracat-rekabetciligi',
    title: "ÜRÜN İHRACAT REKABETÇİLİĞİ",
    description: "Ürünlerinizin uluslararası pazarlarda rekabet gücünü artırmak için etkili stratejiler ve analizler sunuyoruz.",
    detailsList: [
      "İhracata konu olan ürün veya ürün gruplarınıza yönelik küresel ve ülkeler bazında rekabetçilik analizleri yapılmaktadır. Bu bağlamda  ihracat konusunda rahatlıkla giriş yapabileceğiniz veya genişleyebileceğiniz hedef ülke pazarlarının tespiti uzmanlarımızca yapılmaktadır. ",
      "Küresel ticarette başarılı olmanın en önemli unsurlarından biri, ürünlerin uluslararası pazarlarda güçlü bir rekabet avantajına sahip olmasıdır. Bu kapsamda, işletmenizin ihracat yapmayı planladığı ürün veya ürün gruplarına yönelik detaylı rekabetçilik analizleri sunuyoruz. İlk olarak, ürününüzün sektördeki konumunu ve özelliklerini değerlendiriyoruz. Ürün gruplarınızın öne çıkan özellikleri, kalite standartları, fiyatlandırma stratejileri ve pazardaki diğer rakip ürünlerle kıyaslanması gibi temel unsurları ele alıyoruz. Böylece, ürünlerinizin güçlü ve geliştirilmesi gereken yönleri belirlenmiş oluyor.",
      "Ardından, küresel pazarlarda hangi ülkelerin ürünleriniz için daha büyük fırsatlar sunduğunu analiz ediyoruz. Bu süreçte, hedef ülkelerin ekonomik durumu, sektör büyüklüğü, tüketici eğilimleri, yerel rekabet düzeyi ve ithalat politikaları gibi birçok faktörü değerlendiriyoruz. Ayrıca, ürünlerinizin ihracatını artırmak için stratejik olarak uygun olan pazarlarda özel pazarlama ve satış stratejileri öneriyoruz. Müşterilerimize sunduğumuz bu analizlerle, ürünlerinin rekabet gücünü en üst düzeye çıkarmayı ve küresel pazarda sürdürülebilir bir başarı elde etmelerini sağlamayı amaçlıyoruz. Böylece, ihracat süreçlerinizde hem maliyet avantajı hem de uzun vadeli büyüme fırsatları elde etmeniz için gerekli tüm desteği sağlıyoruz."
    ],
    tags: ["İhracat", "Rekabetçilik", "Pazar Araştırması", "Hedef Ülke Analizi", "Küresel Pazar", "Uluslararası Pazarlar", "Uluslararası Ticaret"],
    image: "/assets/Urun-Ihracat-Rekabetciligi.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 'ihracat-hedef-pazar-tespiti',
    title: "İHRACAT HEDEF PAZAR TESPİTİ",
    description: "Ürünleriniz için en kazançlı ve uygun pazarları tespit ederek, doğru hedeflere ulaşmanızı sağlıyoruz.",
    detailsList: [
      "İhracata konu olan ürün veya ürün gruplarınıza yönelik ilgili veritabanları kullanılarak isabetli bir şekilde hedef ülke pazar grupları belirlenmektedir. Bu konuda örnek hedef pazar araştırma raporu isteyen firmalara örnek pazar araştırma raporu gönderilir.",
      "Uluslararası ticaret yaparken, doğru hedef pazarları belirlemek başarıya ulaşmada kritik bir öneme sahiptir. İşletmenizin ihracata konu olan ürünleri için en kazançlı ve sürdürülebilir pazarları tespit etmek amacıyla geniş kapsamlı bir hedef pazar analizi yapmaktayız. Bu süreçte, ilk olarak ihracata konu ürün veya ürün gruplarınızın özelliklerini analiz ediyoruz. Ürünlerinizin hangi pazarlarda daha fazla talep görebileceğini, tüketici profilleri ve eğilimleri üzerinden değerlendirmekteyiz. Bu sayede, hedef pazar seçiminde isabetli bir karar süreci yürütüyoruz.",
      'Pazar araştırmalarında, ilgili ülkelerin ekonomik durumları, sektör büyüklükleri, ithalat oranları, yerel tüketim alışkanlıkları ve rekabet düzeyleri gibi çeşitli verileri kullanıyoruz. Ayrıca, gümrük politikaları, vergi oranları ve diğer yasal düzenlemeler gibi faktörleri de analiz ederek işletmenize uygun pazarların seçimini sağlıyoruz. Bu kapsamda, ihracat potansiyeli yüksek olan ve sürdürülebilir ticaret fırsatları sunan hedef ülkeler belirlenmektedir. Hedef pazar araştırmalarımız, pazara giriş stratejilerinizi şekillendirmeniz ve en uygun müşterilere ulaşmanız için size yol göstermektedir. Bu analizler ile birlikte, işletmenizin ihracat süreçlerinde riskleri azaltarak başarı oranını artırmak için ihtiyaç duyduğunuz tüm desteği sunuyoruz.'
    ],
    tags: ["İhracat", "Pazar Araştırması", "Hedef Ülke Tespiti", "Küresel Pazar", "İthalat Oranları", "Uluslararası Pazarlar", "Rekabetçilik", "Uluslararası Ticaret"],
    image: "/assets/Ihracat-Hedef-Pazar-Tespiti.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 'ihracat-hedef-musteri-tespiti',
    title: "İHRACAT HEDEF MÜŞTERİ TESPİTİ",
    description: "İhracat yapmak istediğiniz bölgelerdeki potansiyel müşterilerinizi analiz edip, size en uygun iş ortaklarını buluyoruz.",
    detailsList: [
      "İhracata konu olan ürün veya ürün gruplarınıza yönelik ilgili veritabanları kullanılarak isabetli bir şekilde hedef ülke pazar grupları ve diğer ülke grupları bazında konşimento ile gümrük verileri baz alınarak potansiyel müşteri listeleriniz sunulmaktadır. ",
      "Uluslararası pazarlarda doğru müşterilere ulaşmak, başarılı bir ihracat stratejisinin en kritik bileşenlerinden biridir. İhracata yönelik potansiyel müşteri analizleri kapsamında, işletmenizin hedeflediği bölgelerde detaylı araştırmalar yapıyoruz. Öncelikle ihracatını yapmak istediğiniz ürün veya ürün gruplarını değerlendiriyor ve bu ürünlerin en fazla talep görebileceği müşteri profillerini belirliyoruz. İlgili veritabanlarını kullanarak hedef ülke ve sektör bazında müşteri listeleri oluşturuyoruz. Bu listeler, gümrük verileri, konşimento bilgileri, ithalat miktarları ve ticaret hacimleri gibi güvenilir verilere dayanmaktadır.",
      "Müşteri tespit sürecinde, firmaların güvenilirliği, sektördeki itibarı, finansal gücü ve ürünlerinize olan ilgileri gibi faktörleri de göz önünde bulunduruyoruz. Bu kapsamlı analizler sayesinde, sizin için en uygun iş ortaklarını belirliyor ve uzun vadeli iş birlikleri kurmanıza yardımcı oluyoruz. Potansiyel müşterilerle sürdürülebilir bir ilişki geliştirebilmeniz için de yol gösterici destek sağlıyoruz. Böylelikle, işletmenizin uluslararası pazarda daha etkili bir şekilde faaliyet göstermesini ve rekabet avantajı kazanmasını sağlamaktayız. İşletmenizin büyüme hedefleri doğrultusunda, ihracat yolculuğunuzda yanınızda olmaktan mutluluk duyuyoruz."
    ],
    tags: ["İhracat", "Müşteri Analizi", "Hedef Ülke Analizi", "İthalat Miktarları", "Ticaret Hacimleri", "İş Ortakları", "İhracat Stratejisi"],
    image: "/assets/Ihracat-Hedef-Musteri-Tespiti.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 'urun-ithalat-rekabetciligi',
    title: "ÜRÜN İTHALAT REKABETÇİLİĞİ",
    description: "İthalat sürecinizdeki rekabet avantajlarını en üst seviyeye çıkararak, daha verimli ve kârlı ticaret fırsatları sunuyoruz.",
    detailsList: [
      "Dış alemden ithalat yapan işletmelere yönelik, ithalata konu olan ürünlerle ilgili rekabetçi yaklaşımlar sunulmaktadır.  ",
      "İthalat süreçlerinde başarı, doğru ürünleri rekabetçi fiyatlarla tedarik edebilmek ve maliyet avantajı sağlayabilmekle mümkündür. İşletmenizin ithalata konu olan ürünleri için kapsamlı bir rekabetçilik analizi yaparak, ithalat sürecinde rekabet avantajını en üst seviyeye çıkarıyoruz. İlk olarak ithalat yapmak istediğiniz ürünlerin pazar içindeki rekabet koşullarını inceliyoruz. Bu süreçte, ithalat yapılan ülkelerdeki yerel fiyatlandırmalar, ürün kalitesi, lojistik maliyetler ve tedarik zinciri yönetimi gibi faktörleri analiz ederek işletmenize uygun maliyet düşürücü stratejiler geliştiriyoruz.",
      "Ayrıca, küresel piyasalarda fiyat avantajı sunan tedarikçiler ve uygun ödeme koşulları sağlayan ülkeler ile ilgili detaylı araştırmalar yapıyoruz. Bu analizler sonucunda, ithalat süreçlerinizde maliyetlerinizi optimize ederek daha verimli ticaret fırsatları elde etmenizi sağlıyoruz. Ayrıca, lojistik maliyetlerinizi düşürmek ve tedarik zincirinizi güvence altına almak için alternatif nakliye seçeneklerini değerlendiriyoruz. Bu kapsamlı yaklaşımla, ithalat süreçlerinizde sürdürülebilir ve kârlı bir yapı oluşturmanıza yardımcı oluyoruz. İşletmenizin büyüme hedefleri doğrultusunda, ithalat işlemlerinizde avantajlı bir konuma gelmenizi sağlamak için yanınızdayız."
    ],
    tags: ["İthalat", "Rekabetçilik", "Pazar Araştırması", "Hedef Ülke Analizi", "Küresel Pazar", "İthalat Miktarları", "Ticaret Hacimleri", "Lojistik Maliyetler", "Tedarik Zinciri"],
    image: "/assets/Urun-Ithalat-Rekabetciligi.webp", // Görsel dosya yolunu güncelle
  }, {
    id: 'ithalat-hedef-tedarikci-ulke-tespiti',
    title: "İTHALAT HEDEF TEDARİKÇİ ÜLKE TESPİTİ",
    description: "İthalat yapmak istediğiniz ürünler için en uygun tedarikçi ülkeleri belirleyerek, tedarik zincirinizi güçlendiriyor ve maliyetlerinizi optimize ediyoruz.",
    detailsList: [
      "Dış alemden ithalat yapan işletmelere yönelik, ithalata konu olan ürünlerle ilgili rekabetçi yaklaşımlar sunulmaktadır.  ",
      "İthalat süreçlerinde doğru tedarikçi ülkeleri belirlemek, maliyetlerin optimize edilmesi ve tedarik zincirinin güçlendirilmesi açısından büyük önem taşır. İşletmenizin ithalatını gerçekleştirmeyi planladığı ürün veya ürün grupları için en uygun tedarikçi ülkeleri tespit ediyoruz. Bu süreçte, ürünlerinizin ithalatında kalite, fiyat avantajı ve güvenilirlik gibi kriterleri ön planda tutarak, çeşitli ülkelerdeki tedarikçileri analiz ediyoruz.",
      "Analiz sürecinde, tedarikçi ülkelerin üretim kapasitesi, fiyatlandırma stratejileri, lojistik altyapıları ve ürünlerin gümrükleme süreçleri gibi önemli detayları dikkate alıyoruz. Tedarik zincirinizi daha sağlam ve sürdürülebilir hale getirebilmek adına alternatif tedarikçi ülkeleri de değerlendiriyoruz. Ayrıca, maliyetlerinizi optimize etmek için lojistik maliyetlerin yanı sıra, ilgili ülkelerin gümrük ve vergi politikaları gibi ekonomik unsurları da analiz ediyoruz. Bu kapsamlı yaklaşım sayesinde, ithalat süreçlerinizde maliyet avantajı elde ederek işletmenizin rekabet gücünü artırıyoruz. İşletmenizin ithalat işlemlerinde avantajlı bir konuma gelmesi için kapsamlı ve güvenilir çözümler sunmaktayız."
    ],
    //according to the details list create tags
    tags: ["İthalat", "Tedarikçi Analizi", "Hedef Ülke Tespiti", "Ticaret Hacimleri", "Lojistik Maliyetler", "Tedarik Zinciri", "Maliyet Optimizasyonu"],
    image: "/assets/Ithalat-Hedef-Pazar-Tespiti.webp", // Görsel dosya yolunu güncelle
  }, {
    id: 'uluslararasi-lojistik-operasyonlari-egitimleri',
    title: "ULUSLARARASI LOJİSTİK OPERASYONLARI EĞİTİMLERİ",
    description: "Lojistik süreçlerinizi hızlandırmak ve optimize etmek için alanında uzman eğitimlerle destek veriyoruz.",
    detailsList: [
      "İhracat, ithalat ve transit ticaret yönlü olarak ilgili lojistik süreçler ve detayları uygulamalı olarak anlatılmaktadır.",
      "Lojistik süreçlerinizi daha verimli hale getirmek ve küresel ticarette etkin bir rol oynamak için işletmenize özel eğitimler sunuyoruz. Uluslararası lojistik operasyonları eğitimi ile tedarik zinciri yönetimi, nakliye süreçleri, gümrükleme ve lojistik maliyet analizleri gibi birçok alanda kapsamlı bilgi sahibi olmanızı sağlıyoruz. Eğitimlerimiz, uluslararası lojistik alanındaki en güncel uygulamaları ve stratejileri içermektedir. Bu eğitimlerde, lojistik süreçlerinizi optimize etmenize ve operasyonel verimliliğinizi artırmanıza yardımcı olacak yöntemler ve pratik bilgiler sunulmaktadır.",
      "Eğitim programlarımız, işletmenizin ihtiyaçlarına göre özelleştirilebilir ve her seviyeden çalışana hitap etmektedir. Ayrıca, küresel ticarette rekabet gücünüzü artırmak için lojistik süreçlerinizin hızlandırılmasını, maliyetlerin düşürülmesini ve tedarik zincirinizin güçlendirilmesini hedeflemekteyiz. Uzman eğitmenlerimiz, sektördeki en güncel bilgi ve deneyimlerini paylaşarak, işletmenizin lojistik alanında daha güçlü bir yapıya kavuşmasını sağlar. Bu kapsamda sunmuş olduğumuz eğitimlerle, işletmenizin uluslararası ticaret süreçlerinde etkin ve verimli bir yapı kurmasına destek oluyoruz."
    ],
    tags: ["Lojistik", "Eğitim", "Tedarik Zinciri", "Maliyet Optimizasyonu", "Nakliye Seçenekleri", "Gümrük Süreçleri", "Lojistik Maliyetler", "Gümrük Muafiyetleri", "Gümrük Muhasebesi", "Gümrük Riskleri", "Gümrük Stratejileri"],
    image: "/assets/Lojistik-Operasyonlari-Egitimleri.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 'gumruk-operasyonlari-egitimleri',
    title: "GÜMRÜK OPERASYONLARI EĞİTİMLERİ",
    description: "Gümrük süreçlerini daha etkin yönetebilmeniz için uzmanlarımızdan eğitim alarak yasal ve operasyonel süreçlerde avantaj elde edebilirsiniz.",
    detailsList: [
      "İhracat ve ithalat  yönlü olarak ilgili gümrük rejimleri ve detayları uygulamalı olarak anlatılmaktadır. ",
      `Uluslararası ticarette başarıya ulaşmanın temel unsurlarından biri, gümrük operasyonlarını doğru ve etkili bir şekilde yönetebilmektir. Gümrük işlemleri, uluslararası ticaretin en karmaşık aşamalarından biri olup, prosedürlerin doğru bir şekilde yürütülmesi, maliyetlerin düşürülmesi ve operasyonel verimliliğin artırılması açısından oldukça kritiktir. Bu bağlamda sunduğumuz "Gümrük Operasyonları Eğitimleri", firmaların gümrük süreçlerinde uzmanlaşmasını sağlamak amacıyla kapsamlı bir içerik sunar.`,
      "Eğitimlerimizde, temel gümrük mevzuatları, ithalat ve ihracat işlemlerinde gerekli belgeler, tarife kodları, vergiler ve gümrük muafiyetleri gibi konular ele alınır. Ayrıca, değişen uluslararası düzenlemeler ve anlaşmalar hakkında güncel bilgiler verilerek, firmaların yasal uyumluluklarını sürdürebilmeleri sağlanır. Bu eğitimlerde, pratik uygulamalar ve vaka çalışmaları üzerinden gümrük süreçleri adım adım incelenir. Gümrük işlemleri sırasında karşılaşılabilecek olası sorunlar, riskler ve çözüm yolları detaylı bir şekilde ele alınır. Böylece katılımcılar, gümrük operasyonlarında profesyonel bir bilgi birikimine sahip olarak ticaret süreçlerini daha etkin yönetebilir.",
      "Aynı zamanda, lojistik ve tedarik zinciri süreçleri ile entegre çalışan gümrük operasyonları hakkında detaylı bilgiler sunulmaktadır. Gümrük beyannameleri, transit rejimi, menşe kuralları ve belge hazırlama süreçleri gibi önemli konular, katılımcıların pratik becerilerini geliştirmelerine yardımcı olacak şekilde aktarılır. Bu eğitimler sayesinde, firmanızın ithalat ve ihracat işlemlerinde maliyet avantajı sağlayarak zamandan tasarruf etmesi hedeflenir. Gümrük mevzuatına hâkim, hızlı ve güvenilir gümrük operasyonları yürütme kabiliyetine sahip personel yetiştirilmesi, global pazarlarda daha güvenilir bir ticaret ortağı olmanızı sağlar.",
      "Ayrıca, katılımcılarımıza risk yönetimi ve gümrük prosedürlerindeki güncel değişiklikler hakkında bilgi verilerek, uluslararası ticaretin dinamik yapısına uyum sağlamaları kolaylaştırılır. Eğitim sonunda, katılımcılar gümrük süreçlerinin her aşamasını sorunsuzca yönetebilecek bilgi ve donanıma sahip olur. Firmanızın küresel pazarlarda rekabet avantajı elde etmesi için gerekli olan bu eğitim, hem operasyonel verimliliğinizi artırır hem de yasal uyum süreçlerinizi daha hızlı ve etkili hale getirir."
    ],
    tags: ["Gümrük", "Eğitim", "Gümrük Muhasebesi", "Gümrük Riskleri", "Gümrük Stratejileri", "Lojistik Maliyetler", "Gümrük Muafiyetleri", "Gümrük Süreçleri", "Nakliye Seçenekleri", "Maliyet Optimizasyonu", "Tedarik Zinciri", "Risk Yönetimi"],
    image: "/assets/Gumruk-Operasyonlari-Egitimleri.webp", // Görsel dosya yolunu güncelle
  },
];